import React from "react";

function Home(){

    return (
<div>
    <div className="jumbotron">
        <h1>Hello All <strong>Math Lovers</strong>!</h1>
        <p className="lead">Welcome to the <strong>Math Tools</strong> portal. This website is a place where you can learn solve fractions, decimals, and many more problems!</p>
    </div>
</div>

    );
}

export default Home;